export default {
  data() {
    return {
    }
  },
  computed: {
    getPointType() {
      const typePointArray = [
        {
          key: 0,
          value: '支払付与'
        },
        {
          key: 1,
          value: '購入付与'
        },
        {
          key: 2,
          value: 'QR付与'
        },
        {
          key: 3,
          value: '手動付与'
        },
        {
          key: 4,
          value: '会員登録付与'
        },
        {
          key: 5,
          value: '支払消費'
        },
        {
          key: 6,
          value: 'QR消費'
        },
        {
          key: 7,
          value: '手動消費'
        },
        {
          key: 8,
          value: 'キャンセル'
        },
        {
          key: 9,
          value: '失効ポイント'
        },
        {
          key: 10,
          value: '付与取消'
        },
        {
          key: 11,
          value: '加算予定'
        },
        {
          key: 12,
          value: 'スタンプラリー付与'
        },
        {
          key: 13,
          value: 'ポイント連携付与',
        }
      ];
      return (type) => {
        return typePointArray.find(e => e.key === type)?.value;
      }
    },
  }
};
